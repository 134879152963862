import React, { useRef, useEffect, useState } from 'react';
import * as maptilersdk from '@maptiler/sdk';
import { WindLayer, PrecipitationLayer, PressureLayer, RadarLayer, TemperatureLayer, ColorRamp } from "@maptiler/weather";
import "@maptiler/sdk/dist/maptiler-sdk.css";
import './map.css';
import '@fortawesome/fontawesome-free/css/all.css';
function Map({ onSelectedLocationChange }) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [zoom] = useState(5);
  maptilersdk.config.apiKey = 'q1EzzF3AUpnluWEkivcz';
  const [selectedLocation, setSelectedLocation] = useState("W McCamey")
  const points_data = {
    'Point': ['C Coast North', 'C Coast South', 'C Coast Tip', 'C Corpus Christi', 'E East Ex', 'Houston Central', 'Houston North', 'Houston South', 'N Far East', 'N Seymour', 'N Sherman', 'N Wichita Falls', 'NC Dallas Central', 'NC Dallas East', 'NC Dallas South', 'NC Fort Worth', 'NC West', 'PH Amarillo NE', 'PH Amarillo NW', 'PH Amarillo S', 'PH Plainview', 'S NELOB', 'S NELOB 2', 'S San Antonio', 'SC Austin Central', 'SC Austin South', 'SC Killeen', 'W Abilene', 'W Bigspring', 'W Brady', 'W Del Rio', 'W Far Fort Stockton', 'W Lubbock', 'W McCamey', 'W Odessa', 'W San Angelo'],
    'Latitude': [27.0327556, 26.3703031, 26.1029844, 28.0675405, 31.5368754, 29.7539955, 30.068328, 29.2722721, 33.3007417, 33.4279028, 33.5660293, 34.1441764, 32.7320187, 32.921935, 32.0798244, 32.7511955, 32.9658655, 35.3329742, 35.2657739, 34.7696136, 34.2029219, 27.3783759, 26.4947247, 29.3970768, 30.3307507, 29.9112772, 31.4056918, 32.3711748, 32.2829522, 31.2576656, 29.7696868, 31.175133, 33.096484, 30.9918556, 31.8873032, 31.4538833],
    'Longitude': [-97.6451486, -98.5259645, -97.4863752, -97.4300671, -95.0216238, -95.3540686, -95.7551159, -95.5750849, -95.4188964, -99.3503564, -96.4844269, -99.630414, -96.8083054, -96.3591872, -96.7043033, -97.3232375, -98.5813091, -101.224949, -102.4724809, -102.2793292, -101.198323, -98.7748432, -98.9024029, -98.4847577, -97.6937421, -98.0289595, -97.9769461, -100.1851961, -101.5145438, -99.5825919, -100.8138593, -103.6702681, -101.2909312, -102.197128, -102.6461378, -100.3296417]
  };
  const [activeLayer, setActiveLayer] = useState("normal");
  const [currentTime, setCurrentTime] = useState(new Date());
  const [minTime, setMinTime] = useState(new Date(new Date().setDate(new Date().getDate() - 3)));
  const [maxTime, setMaxTime] = useState(new Date(new Date().setDate(new Date().getDate() + 3)));
  const [isPlaying, setIsPlaying] = useState(true);

  const weatherLayers = {
    normal: { id: null },
    precipitation: new PrecipitationLayer({ id: 'precipitation' }),
    pressure: new PressureLayer({ id: 'pressure' }),
    radar: new RadarLayer({ id: 'radar' }),
    temperature: new TemperatureLayer({ id: 'temperature' }),
    wind: new WindLayer({
      id: "wind",
      speed: 0.001,
      fadeFactor: 0.03,
      maxAmount: 256,
      density: 200
    })
  };
  const selectLocationChange = (point) => {
    setSelectedLocation(point);
    onSelectedLocationChange(point);
  }
  useEffect(() => {
    if (weatherLayers.wind && map.current) {
      weatherLayers.wind.setAnimationTime(currentTime);
      map.current.triggerRepaint();
    }
  }, [currentTime, weatherLayers.wind]);
  useEffect(() => {
    if (map.current) return; // prevents map reinitialization

    map.current = new maptilersdk.Map({
      container: mapContainer.current,
      style: maptilersdk.MapStyle.BASIC,
      zoom: zoom,
      center: [-99.9018, 31.9686]
    });

    map.current.on('load', () => {
      // Set initial weather layer

      map.current.setPaintProperty("Water", 'fill-color', "rgba(0, 0, 0, 1)");
      //map.current.addLayer(weatherLayers[activeLayer], "Water");

      points_data.Point.forEach((point, index) => {
        new maptilersdk.Marker({ color: "#FF0000" })
          .setLngLat([points_data.Longitude[index], points_data.Latitude[index]])
          .setPopup(new maptilersdk.Popup()
            .setText(point)
            .on('open', function () {
              selectLocationChange(point);
            })
          ) // Optional: Add popups
          .addTo(map.current);
      });
    });
  }, [zoom]); // zoom is a dependency here

  const handlePlayPause = () => {
    if (!isPlaying) {
      const interval = setInterval(() => {
        setCurrentTime(prevTime => {
          let newTime = new Date(prevTime.getTime() + 60000);
          if (newTime > maxTime) {
            clearInterval(interval);
            newTime = minTime;
          }
          return newTime;
        });
      }, 1000); // Adjust interval speed to control animation speed
      setIsPlaying(true);
    } else {
      //clearInterval(interval);
      setIsPlaying(false);
    }
  };

  const sliderChange = (value) => {
    weatherLayers[activeLayer].setAnimationTime(parseInt(value));
  }

  const refreshTime = () => {
    if (activeLayer !== "normal" && weatherLayers[activeLayer]) {

      const d = weatherLayers[activeLayer].getAnimationTimeDate();
      console.log(d)
      setCurrentTime(d);
    }
  }

  const handleLayerChange = (layerType) => {
    if (activeLayer !== layerType) {
      map.current.removeLayer(activeLayer);
      if (layerType != "normal") {
        const layer = weatherLayers[layerType]
        map.current.addLayer(layer, "Water");
        layer.on("sourceReady", event => {
          const startDate = layer.getAnimationStartDate();
          const endDate = layer.getAnimationEndDate();
          const currentDate = layer.getAnimationTimeDate();
          refreshTime()
          setMinTime(startDate);
          setMaxTime(endDate);
          setCurrentTime(currentDate);
          console.log("startDate :", +startDate)
          console.log("endDate :", +endDate)
          console.log("currentDate :", +currentDate)
        });
        layer.on("animationTimeSet", event => {
          refreshTime()
        });

      }

      setActiveLayer(layerType);
    }
  };

  return (
    <div className="map-wrap" style={{ width: "100%" }}>
      <div ref={mapContainer} className="map" />
      <div className="layer-buttons">
        {Object.keys(weatherLayers).map(layer => (
          <button key={layer} onClick={() => handleLayerChange(layer)} className='layer-btn'>
            {/* {layer.charAt(0).toUpperCase() + layer.slice(1)} */}
            <i className={`fa ${iconClasses[layer]}`}></i>
          </button>

        ))}
      </div>
      <div className="layer-selected">
        <div className='selected-point'>
          <p> <i class="fa-solid fa-location-dot mr-1"></i>   {selectedLocation || ""} </p>
        </div>

      </div>
      { activeLayer !== "normal" && 
      <div className="layer-slider">
        <div>
          <button
            style={
              {
                margin: 0,
                marginBottom: "4px",
                marginRight: "5px",
                height: "30px",
                paddingTop: 1,
                color: "black"
              }
            }
            onClick={handlePlayPause}>{isPlaying ? 'Pause' : 'Play'}</button>
          <span
            style={{
              color: "white",
              backgroundColor: "rgba(0,0,0,0.1)"
            }}
          >{currentTime.toString()}</span>
        </div>
        <input
          style={{
            width: "100%"
          }}
          type="range"
          min={minTime.getTime()}
          max={maxTime.getTime()}
          value={currentTime.getTime()}
          onChange={(e) => sliderChange(e.target.value)}
          step="1"
        />
      </div>}
    </div>
  );
}
const iconClasses = {
  normal: 'fa-circle', // Generic circle icon for normal
  precipitation: 'fa-cloud-rain',
  pressure: 'fa-tachometer-alt',
  radar: 'fa-satellite-dish',
  temperature: 'fa-thermometer-three-quarters',
  wind: 'fa-wind'
};
export default Map;