// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-wrap {
    position: relative;
    width: 100%;
    height: calc(100vh - 77px); /* calculate height of the screen minus the heading */
  }
  
  .map {
    position: absolute;
    width: 100%;
    height: 100%;
  }

.layer-btn{
    background-color: #3576D2;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 0;
}
.layer-buttons{
    position: absolute;
    display: flex;
    flex-direction: column;
}
.layer-selected{
  position: absolute;
    left: 75px;
    align-items: center;
    top: 20px;
    padding: 4px;
    background-color: #3576D2;
    z-index: 99999;
    font-weight: bolder;
    color: white;
    border-radius: 21px;
}
.layer-selected  p{
  margin: 0;
  padding-left: 20px;
  padding-right: 20px;
}
.layer-selected  p i{
  margin-right: 10px;
}
.layer-slider {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 35px;
    left: 75px;
    align-items: center;
}
.layer-slider input {
  width: 400px;
}`, "",{"version":3,"sources":["webpack://./src/map.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,0BAA0B,EAAE,qDAAqD;EACnF;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;EACd;;AAEF;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,UAAU;AACd;AACA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;AAC1B;AACA;EACE,kBAAkB;IAChB,UAAU;IACV,mBAAmB;IACnB,SAAS;IACT,YAAY;IACZ,yBAAyB;IACzB,cAAc;IACd,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;AACvB;AACA;EACE,SAAS;EACT,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,YAAY;IACV,UAAU;IACV,mBAAmB;AACvB;AACA;EACE,YAAY;AACd","sourcesContent":[".map-wrap {\n    position: relative;\n    width: 100%;\n    height: calc(100vh - 77px); /* calculate height of the screen minus the heading */\n  }\n  \n  .map {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n  }\n\n.layer-btn{\n    background-color: #3576D2;\n    width: 50px;\n    height: 50px;\n    border-radius: 50%;\n    padding: 0;\n}\n.layer-buttons{\n    position: absolute;\n    display: flex;\n    flex-direction: column;\n}\n.layer-selected{\n  position: absolute;\n    left: 75px;\n    align-items: center;\n    top: 20px;\n    padding: 4px;\n    background-color: #3576D2;\n    z-index: 99999;\n    font-weight: bolder;\n    color: white;\n    border-radius: 21px;\n}\n.layer-selected  p{\n  margin: 0;\n  padding-left: 20px;\n  padding-right: 20px;\n}\n.layer-selected  p i{\n  margin-right: 10px;\n}\n.layer-slider {\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  bottom: 35px;\n    left: 75px;\n    align-items: center;\n}\n.layer-slider input {\n  width: 400px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
