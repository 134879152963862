import React, { useEffect } from "react";
import "./App.css";
import Map from "./WindyMap";
import Data from "./old table"; // Make sure the path is correct
import ResponsiveAppBar from "./navbar";
import WeatherForecast from "./WeatherForcast";
import { useState } from "react";

function Home() {
  const [selectedLocation, setSelectedLocation] = useState("");
  const [isPanelOpen, setPanelOpen] = useState(false);
  const [isWeatherModelsOPen, setWeatherModelOpen] = useState(false);

  const handleSelectedLocationChange = (loc) => {
    setSelectedLocation(loc);
    setPanelOpen(true);
  };

  const togglePanel = () => {
    setPanelOpen(!isPanelOpen);
  };

  const toggleWeatherModel = () => {
    setWeatherModelOpen(!isWeatherModelsOPen);
  };

  useEffect(() => {
    // Set the default location when the component mounts
    const defaultLocation = "W McCamey"; // Replace with your desired default location
    setSelectedLocation(defaultLocation);
    setPanelOpen(true);
  }, []);

  return (
    <div className="App" style={{ height: "100%" }}>
      <ResponsiveAppBar />
      <Map onSelectedLocationChange={handleSelectedLocationChange} />
      <div style={{ position: "relative" }}>
        <Data
          selectedLoc={selectedLocation}
          isOpen={isPanelOpen}
          togglePanel={togglePanel}
        />
      </div>
    </div>
  );
}

export default Home;
