import React, { useState } from "react";
import { NavLink,Link, useNavigate } from "react-router-dom";
import { AppBar, Box, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemText, Button, Avatar, Tooltip, MenuItem, Menu, Container } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

function ResponsiveAppBar() {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    localStorage.removeItem("userToken");
    navigate("/");
  };

  return (

    <nav class="navbar navbar-expand-sm navbar-dark " style={{backgroundColor:"#3576D2"}}>
      <div class="container-fluid" style={{
        justifyContent: "space-around"
      }}> 
        <Link class="navbar-brand"
          component={Link}
          to="/home">ERCOT Weather</Link>
        <div>
          
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse ml-5" id="mynavbar">
      <ul className="navbar-nav me-auto">
        <li className="nav-item">
          <NavLink className="nav-link" activeClassName="active" to="/home">Home</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" activeClassName="active" to="/history">History</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" activeClassName="active" to="/wind-map">Wind Map</NavLink>
        </li>
      </ul>
    </div>

        </div>
        <div class="nav-item dropdown">
        <Avatar class="nav-link dropdown-toggle"  alt="User Avatar" role="button" data-bs-toggle="dropdown" src="/static/images/avatar/2.jpg" />

          <ul class="dropdown-menu">
            <li><Link class="dropdown-item" onClick={handleCloseUserMenu}>Logout</Link></li>
          </ul>
        </div>
        </div>

        <div>
      </div>
    </nav>
  );
}

export default ResponsiveAppBar;
