import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import "./WeatherForecast.css";

const WeatherForecastIcone = ({ selectedLoc, isOpen }) => {
  const [iconData, setIconData] = useState([]);

  useEffect(() => {
    const fetchWeatherData = async () => {
      try {
        const requestData = {
          date: moment.utc().format("YYYY-MM-DD"),
          cities: [
            {
              name: selectedLoc,
              latitude: 40.7128,
              longitude: -74.006,
            },
          ],
        };

        const iconResponse = await axios.post(
          "https://weather.playalab.com:8443/weather_forecast_icon",
          requestData
        );

        setIconData(iconResponse.data);
      } catch (error) {
        console.error("Error fetching weather data:", error);
      }
    };

    fetchWeatherData();
  }, []);

  const getColorForTemperature = (temp, minTemp, maxTemp) => {
    let ratio = (temp - minTemp) / (maxTemp - minTemp);
    let hue = (1 - ratio) * 240;
    return `hsl(${hue}, 100%, 50%)`;
  };

  const getTemperatureBackgroundColor = (value, nextValue) => {
    if (!value){
      return{
        background: `linear-gradient(to right, #fff, #fff)`,
        textAlign: "center",
      };
    }
    let minTemp = 32;
    let maxTemp = 90;

    let currentColor = getColorForTemperature(value, minTemp, maxTemp);
    let nextColor =
      nextValue !== undefined && nextValue !== null
        ? getColorForTemperature(nextValue, minTemp, maxTemp)
        : currentColor;

    return {
      background: `linear-gradient(to right, ${currentColor}, ${nextColor})`,
      textAlign: "center",
    };
  };

  const panelStyles = {
    backgroundColor: "#fff",
    transition: "transform 0.3s ease-in-out",
    maxHeight: "90vh",
    overflow: "auto",
    zIndex: 99999,
  };

  const renderHorizontalTable = (data, model) => {
    if (typeof data == "string"){
     data = JSON.parse(data);
    }
    if (!Array.isArray(data) || data.length === 0) {
      return <p>No data available.</p>;
    }

    const dates = [
      ...new Set(
        data.map((entry) => moment.utc(entry.date).format("MM/DD/YYYY"))
      ),
    ];
    const columns = Object.keys(data[0]).filter(
      (key) => key !== "date" && key !== "city"
    );

    const getHourlyData = (date, hour) => {
      const dateTimeString = moment
        .utc(`${date} ${hour.toString().padStart(2, "0")}:00`)
        .format("YYYY-MM-DD HH:mm");
      const entry = data.find(
        (d) => moment.utc(d.date).format("YYYY-MM-DD HH:mm") === dateTimeString
      );
      return entry ? entry : {};
    };

    const hours = Array.from({ length: 24 }, (_, index) => index);

    return (
      <table
        className="weather-table"
        style={{ maxHeight: 800, overflowX: "auto" }}
      >
        <thead>
          <tr>
            <th rowSpan="2">Parameter</th>
            {dates.map((date, index) => (
              <th key={index} colSpan="24">
                {date}
              </th>
            ))}
          </tr>
          <tr>
            {dates.map((date, index) =>
              hours.map((hour, hourIndex) => (
                <th key={`${index}-${hourIndex}`}>{ hour<23 ? (hour+1) : 0 }</th>
              ))
            )}
          </tr>
        </thead>
        <tbody>
          {columns.map((column, index) => (
            <tr key={index}>
              <td>{column.replace("_", " ")}</td>
              {dates.map((date, dateIndex) =>
                hours.map((hour, hourIndex) => {
                  const entry = getHourlyData(date, hour);
                  const nextEntry =
                    hourIndex < 23 ? getHourlyData(date, hour + 1) : null;
                  const style = column.startsWith("temperature")
                    ? getTemperatureBackgroundColor(
                        entry[column],
                        nextEntry ? nextEntry[column] : null
                      )
                    : {};

                  return (
                    <td key={`${dateIndex}-${hourIndex}`} style={style}>
                      {entry[column] !== undefined ? entry[column] : "N/A"}
                    </td>
                  );
                })
              )}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div style={panelStyles}>
      <div style={{ height: "100%" }}>
        <h2>Weather Forecast (ICON)</h2>
        {renderHorizontalTable(iconData, "icon")}
      </div>
    </div>
  );
};

export default WeatherForecastIcone;
