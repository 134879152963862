import React, { useState, useRef } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControl } from "@mui/material";

import axios from "axios";
import { useEffect } from "react";

import {
  faTint,
  faCloudShowersHeavy,
  faSnowflake,
  faTemperatureHigh,
  faSun,
  faCloud,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight
} from "@fortawesome/free-solid-svg-icons";
import WeatherForecast from "./WeatherForcast";
import WeatherForecastIcone from "./WeatherForecastIcone";

function Data({ selectedLoc, isOpen, togglePanel }) {
  function getTexasDate() {
    // Create a new date object with the current date and time
    const currentDate = new Date();
  
    // Get the time in Texas using the 'America/Chicago' time zone
    const texasDate = new Intl.DateTimeFormat('en-US', {
      timeZone: 'America/Chicago',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    }).formatToParts(currentDate);
  
    // Extract parts and create a new Date object
    const year = texasDate.find(part => part.type === 'year').value;
    const month = texasDate.find(part => part.type === 'month').value - 1; // Month is zero-based
    const day = texasDate.find(part => part.type === 'day').value;
    const hour = texasDate.find(part => part.type === 'hour').value;
    const minute = texasDate.find(part => part.type === 'minute').value;
    const second = texasDate.find(part => part.type === 'second').value;
  
    return new Date(year, month, day, hour, minute, second);
  }
  const currentDate = getTexasDate();
  const [isWeatherModelsOPen, setWeatherModelOpen] = useState(false);
  const [model, setModel] = useState("normal");
  const nextDay = new Date(currentDate);
  nextDay.setDate(currentDate.getDate() + 1);
  const toggleWheatherModel = () => {
    setWeatherModelOpen(!isWeatherModelsOPen);
  };
  const categories = {
    Basic: [
      "temp",
      "feelslike",
      "humidity",
      "pressure",
      "uvindex",
      "visibility",
      "precip",
      "precipprob",
      "preciptype",
      "severerisk",
    ],
    "Wave&Tide": ["snow", "snowdepth"],
    Wind: [
      "winddir",
      "winddir100",
      "winddir50",
      "winddir80",
      "windgust",
      "windspeed",
      "windspeed100",
      "windspeed50",
      "windspeed80",
    ],
    Meteogram: ["cloudcover", "sunelevation"],
    "Solar & Radiation": [
      "solarenergy",
      "solarradiation",
      "difradiation",
      "dniradiation",
      "ghiradiation",
      "gtiradiation",
    ],
    Airgram: ["dew"],
    All: [
      "temp",
      "feelslike",
      "humidity",
      "pressure",
      "uvindex",
      "visibility",
      "precip",
      "precipprob",
      "preciptype",
      "severerisk",
      "snow",
      "snowdepth",
      "winddir",
      "winddir100",
      "winddir50",
      "winddir80",
      "windgust",
      "windspeed",
      "windspeed100",
      "windspeed50",
      "windspeed80",
      "cloudcover",
      "sunelevation",
      "solarenergy",
      "solarradiation",
      "difradiation",
      "dniradiation",
      "ghiradiation",
      "gtiradiation",
      "dew",
    ],
    GFS: ["forecast"], // Add "forecast" to the "GFS" category
    Icone: ["Icone"], // Add "Icone" to the "Icone" category
  };

  const [selectedLocation, setSelectedLocation] = useState("W McCamey");
  const [selectedCategorie, setSelectedCategorie] = useState("Basic");
  const [showGfsContent, setShowGfsContent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().substr(0, 10)
  ); // Obtient la date du jour au format ISO (AAAA-MM-JJ)

  const scrollRef = useRef(null);
  const [weatherData1, setWeatherData1] = useState(null);
  const [error, setError] = useState(null);
  const selectCategorie = (cat) => {
    console.log(categories);
    setSelectedCategorie(cat);
    if (cat == "GFS") {
      setModel("GFS");
    } else if (cat == "Icone") {
      setModel("Icone");
    } else {
      setModel("normal");
    }
  };
  const handleChangeDate = (event) => {
    setSelectedDate(event.target.value);
  };
  const handleGfsButtonClick = () => {
    setShowGfsContent(!showGfsContent); // Toggle GFS content visibility
  };
  const panelStyles = {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#fff",
    transition: "transform 0.3s ease-in-out",
    transform: isOpen ? "translateY(0)" : "translateY(100%)",
    maxHeight: "90vh",
    overflow: "auto",
    zIndex: 99999,
  };
  const scrollToPercentage = (percentage) => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      const targetPosition =
        scrollElement.scrollWidth * (percentage / 100) -
        scrollElement.clientWidth / 2;
      scrollElement.scrollTo({ left: targetPosition, behavior: "smooth" });
    }
  };
  function updateLinePosition() {
    const now = new Date();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const totalSeconds = minutes * 60 + seconds;
    const percentOfHour = (totalSeconds / 3600) * 100;

    // Apply the calculated percentage to the CSS variable
    const table = document.querySelector("table");
    if (table) {
      table.style.setProperty("--percent-of-hour", `${percentOfHour}%`);
    }
  }
  const tableBodyRef = useRef(null);

  useEffect(() => {
    if (tableBodyRef.current) {
      const height = tableBodyRef.current.offsetHeight; // Get the height of the table
      tableBodyRef.current.style.setProperty("--table-height", `${height}px`); // Set the CSS variable
    }
  }, [selectedCategorie]);
  // Update position every second to keep it accurate
  setInterval(updateLinePosition, 1000 * 60);

  // Initial call to set the line when the page loads
  updateLinePosition();
  const isCurrentHour = (hour, day) => {
    // Create a Date object from the input
    const inputDate = new Date(`${day}T${hour}`);

    // Créer un objet Date à partir de l'heure actuelle dans le fuseau horaire du Texas
    const currentDate = getTexasDate();
    console.log("inputDate",inputDate)
    console.log("currentDate",currentDate)
    // Comparer l'année, le mois, le jour et l'heure
    return (
      inputDate.getFullYear() === currentDate.getFullYear() &&
      inputDate.getMonth() === currentDate.getMonth() &&
      inputDate.getDate() === currentDate.getDate() &&
      inputDate.getHours() === currentDate.getHours()
    );
  };
  const fetchData = async (
    selectedLocation,
    selectedDate,
    setWeatherData1,
    setError
  ) => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://weather.playalab.com:8443/download-weather",
        {
          point_name: selectedLocation,
          date: selectedDate,
        }
      );

      setWeatherData1(response.data);
      setError(null);
    } catch (error) {
      setError("Error fetching weather data");
      console.error(error);
    } finally {
      scrollToPercentage(54);
      setLoading(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    updateLinePosition();
    setSelectedLocation(selectedLoc);
  }, [selectedLoc]);
  // Utiliser useEffect pour appeler fetchData lors de changements de selectedLocation ou selectedDate
  useEffect(() => {
    if (selectedLocation && selectedDate) {
      fetchData(selectedLocation, selectedDate, setWeatherData1, setError);
    } else {
      setError("Please select a location and date");
    }
  }, [selectedDate, selectedLocation]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (selectedLocation && selectedDate) {
      // Vérifiez si les valeurs sont définies
      fetchData(selectedLocation, selectedDate, setWeatherData1, setError);
    } else {
      setError("Please select a location and date");
    }
  };
  const getWindDirectionIcon = (direction) => {
    // Ensure direction is within 0-360 degrees
    direction = direction % 360;
  
    // Rotate the icon based on the direction
    const style = { transform: `rotate(${direction}deg)` };
  
    return <FontAwesomeIcon icon={faArrowUp} style={style} />;
  };
  const getWindgustBackgroundColor = (temp, NextTemp) => {
    let minTemp = 0;
    let maxTemp = 60;
    let currentColor = getColorForTemperature(temp, minTemp, maxTemp);
    let nextColor = NextTemp
      ? getColorForTemperature(NextTemp, minTemp, maxTemp)
      : currentColor;
    return {
      background: `linear-gradient(to right, ${currentColor}, ${nextColor})`,
      textAlign: "center",
    };
  };

  const getHumidityIcon = (humidity) => {
    if (humidity < 30) {
      return <FontAwesomeIcon icon={faTint} style={{ color: "blue" }} />;
    } else if (humidity >= 30 && humidity < 70) {
      return <FontAwesomeIcon icon={faTint} style={{ color: "green" }} />;
    } else {
      return <FontAwesomeIcon icon={faTint} style={{ color: "red" }} />;
    }
  };
  const getDayOfWeek1 = (dateString) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const date = new Date(dateString);
    const dayOfWeekIndex = date.getDay();
    return daysOfWeek[dayOfWeekIndex];
  };
  const getWindDirectionIcon1 = (direction) => {
   // Ensure direction is within 0-360 degrees
   direction = (direction + 180) % 360;
  
   // Rotate the icon based on the direction
   const style = { transform: `rotate(${direction}deg)` };
 
   return <FontAwesomeIcon icon={faArrowUp} style={style} />;
  };
  const getCellBackgroundGradient = (temp, NextTemp) => {
    let minTemp = 0;
    let maxTemp = 100;
    let currentColor = getColorForTemperature(temp, minTemp, maxTemp);
    let nextColor = NextTemp
      ? getColorForTemperature(NextTemp, minTemp, maxTemp)
      : currentColor;
    return {
      background: `linear-gradient(to right, ${currentColor}, ${nextColor})`,
      textAlign: "center",
    };
  };
  const getColorForTemperature = (temp, minTemp, maxTemp) => {
    let ratio = (temp - minTemp) / (maxTemp - minTemp); // Normalize ratio
    let hue = (1 - ratio) * 240; // Calculate hue from blue to red
    return `hsl(${hue}, 100%, 50%)`; // Return HSL color string
  };
  const getTemperatureBackgroundColor = (dayIndex, hourIndex) => {
    let minTemp = 32;
    let maxTemp = 100;

    let temp = weatherData1.days[dayIndex].hours[hourIndex]?.temp;
    let NextTemp;
    if (
      weatherData1.days[dayIndex].hours[hourIndex].datetime.split(":")[0] ===
      "23"
    ) {
      NextTemp = weatherData1.days[dayIndex + 1]?.hours[0]?.temp;
    } else {
      NextTemp = weatherData1.days[dayIndex].hours[hourIndex + 1]?.temp;
    }
    let currentColor = getColorForTemperature(temp, minTemp, maxTemp);
    let nextColor;

    // Check if NextTemp is undefined or null explicitly
    if (NextTemp !== undefined && NextTemp !== null) {
      nextColor = getColorForTemperature(NextTemp, minTemp, maxTemp);
    } else {
      nextColor = "defaultColor"; // Define a default color if no temperature is provided
    }
    return {
      background: `linear-gradient(to right, ${currentColor}, ${nextColor})`,
      textAlign: "center",
    };
  };
  const getWindspeed50BackgroundColor = (temp, NextTemp) => {
    let minTemp = 0;
    let maxTemp = 50;
    let currentColor = getColorForTemperature(temp, minTemp, maxTemp);
    let nextColor = NextTemp
      ? getColorForTemperature(NextTemp, minTemp, maxTemp)
      : currentColor;
    return {
      background: `linear-gradient(to right, ${currentColor}, ${nextColor})`,
      textAlign: "center",
    };
  };

  const getCloudcoverBackgroundColor = (temp, NextTemp) => {
    let minTemp = 0;
    let maxTemp = 50;
    let currentColor = getColorForTemperature(temp, minTemp, maxTemp);
    let nextColor = NextTemp
      ? getColorForTemperature(NextTemp, minTemp, maxTemp)
      : currentColor;
    return {
      background: `linear-gradient(to right, ${currentColor}, ${nextColor})`,
      textAlign: "center",
    };
  };

  const getSolarRadiationBackgroundColor = (temp, NextTemp) => {
    let minTemp = 0;
    let maxTemp = 1000;
    let currentColor = getColorForTemperature(temp, minTemp, maxTemp);
    let nextColor = NextTemp
      ? getColorForTemperature(NextTemp, minTemp, maxTemp)
      : currentColor;
    return {
      background: `linear-gradient(to right, ${currentColor}, ${nextColor})`,
      textAlign: "center",
    };
  };
  const getSolarEnergyBackgroundColor = (temp, NextTemp) => {
    let minTemp = 0;
    let maxTemp = 8;
    let currentColor = getColorForTemperature(temp, minTemp, maxTemp);
    let nextColor = NextTemp
      ? getColorForTemperature(NextTemp, minTemp, maxTemp)
      : currentColor;
    return {
      background: `linear-gradient(to right, ${currentColor}, ${nextColor})`,
      textAlign: "center",
    };
  };
  const getGhiRadiationBackgroundColor = (temp, NextTemp) => {
    let minTemp = 0;
    let maxTemp = 1000;
    let currentColor = getColorForTemperature(temp, minTemp, maxTemp);
    let nextColor = NextTemp
      ? getColorForTemperature(NextTemp, minTemp, maxTemp)
      : currentColor;
    return {
      background: `linear-gradient(to right, ${currentColor}, ${nextColor})`,
      textAlign: "center",
    };
  };
  const getDniRadiationBackgroundColor = (temp, NextTemp) => {
    let minTemp = 0;
    let maxTemp = 1000;
    let currentColor = getColorForTemperature(temp, minTemp, maxTemp);
    let nextColor = NextTemp
      ? getColorForTemperature(NextTemp, minTemp, maxTemp)
      : currentColor;
    return {
      background: `linear-gradient(to right, ${currentColor}, ${nextColor})`,
      textAlign: "center",
    };
  };
  const getDifRadiationBackgroundColor = (temp, NextTemp) => {
    let minTemp = 0;
    let maxTemp = 1000;
    let currentColor = getColorForTemperature(temp, minTemp, maxTemp);
    let nextColor = NextTemp
      ? getColorForTemperature(NextTemp, minTemp, maxTemp)
      : currentColor;
    return {
      background: `linear-gradient(to right, ${currentColor}, ${nextColor})`,
      textAlign: "center",
    };
  };
  const getGtiRadiationBackgroundColor = (temp, NextTemp) => {
    let minTemp = 0;
    let maxTemp = 1000;
    let currentColor = getColorForTemperature(temp, minTemp, maxTemp);
    let nextColor = NextTemp
      ? getColorForTemperature(NextTemp, minTemp, maxTemp)
      : currentColor;
    return {
      background: `linear-gradient(to right, ${currentColor}, ${nextColor})`,
      textAlign: "center",
    };
  };
  const getSunElevationBackgroundColor = (temp, NextTemp) => {
    let minTemp = -100;
    let maxTemp = 100;
    let currentColor = getColorForTemperature(temp, minTemp, maxTemp);
    let nextColor = NextTemp
      ? getColorForTemperature(NextTemp, minTemp, maxTemp)
      : currentColor;
    return {
      background: `linear-gradient(to right, ${currentColor}, ${nextColor})`,
      textAlign: "center",
    };
  };
  const renderCategoryContent = () => {
    // Return the Forecast component for the "GFS" category
    return (
      <WeatherForecast
        selectedLoc={selectedLocation}
        isOpen={true}
        style={{ height: 800, overflowX: "auto" }}
      />
    );

    // Render other category content here
    // ...
  };
  const renderIconeCategoryContent = () => {
    // Return the Forecast component for the "GFS" category
    return (
      <WeatherForecastIcone
        selectedLoc={selectedLocation}
        isOpen={true}
        style={{ height: 800, overflowX: "auto" }}
      />
    );

    // Render other category content here
    // ...
  };
  return (
    <div style={panelStyles}>
      {/* Render the selected category content */}

      <button
        onClick={togglePanel}
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          width: "30px",
          height: "30px",
          color: "white",
          backgroundColor: "black",
          padding: 0,
          zIndex: 99999,
        }}
      >
        &times;
      </button>

      <Paper>
        <div>
          <div className="catButtonContainer">
            {Object.keys(categories).map((cat) => (
              <button
                key={cat}
                onClick={() => selectCategorie(cat)}
                className="catButton"
              >
                {cat}
              </button>
            ))}

            <input
              type="date"
              value={selectedDate}
              onChange={handleChangeDate}
              style={{ marginRight: "10px" }}
              className="catButton"
            ></input>
          </div>
        </div>
        {model == "GFS" && renderCategoryContent()}
        {model == "Icone" && renderIconeCategoryContent()}
        {model == "normal" && (
          <div
            style={{
              width: "100%",
              display: "flex",
            }}
          >
            <div
              style={{
                width: "95%",
                position: "relative",
              }}
            >
              {loading && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    zIndex: 19000,
                  }}
                >
                  <div className="spinner"></div>
                </div>
              )}

              <TableContainer
                ref={scrollRef}
                style={{ maxHeight: 800, overflowX: "auto" }}
              >
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  style={{ width: "100%" }}
                >
                  <TableHead>
                    <TableRow>
                      {weatherData1 &&
                        weatherData1.days &&
                        weatherData1.days.length > 0 &&
                        weatherData1.days.map((day, dayIndex) => (
                          <React.Fragment key={dayIndex}>
                            <TableCell
                              align="center"
                              colSpan={24}
                              style={{
                                fontWeight: "bold",
                                marginLeft: "120px",
                              }}
                            >
                              {" "}
                              {/* Colspan set to 24 to span all hours */}
                              {`${getDayOfWeek1(day.datetime)}, ${new Date(
                                day.datetime
                              ).toLocaleDateString()}`}
                            </TableCell>
                          </React.Fragment>
                        ))}
                    </TableRow>
                  </TableHead>

                  <TableBody ref={tableBodyRef}>
                    <TableRow
                      style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 9999,
                        backgroundColor: "#fff",
                      }}
                    >
                      <TableCell
                        style={{
                          position: "sticky",
                          left: 0,
                          zIndex: 9999,
                          backgroundColor: "#fff",
                        }}
                      >
                        Hour
                      </TableCell>
                      {weatherData1 &&
                        weatherData1.days &&
                        weatherData1.days.length > 0 &&
                        weatherData1.days.map((day, dayIndex) => (
                          <React.Fragment key={dayIndex}>
                            {day.hours &&
                              day.hours.map((hour, hourIndex) => (
                                <TableCell
                                  key={hourIndex}
                                  align="right"
                                  className={`${
                                    isCurrentHour(hour.datetime, day.datetime)
                                      ? "currentHour"
                                      : ""
                                  }`}
                                >
                                  {hour.datetime.split(":")[0]}
                                  {/* Affiche 24 si l'heure est "00", sinon affiche l'heure normale */}
                                </TableCell>
                              ))}
                          </React.Fragment>
                        ))}
                    </TableRow>

                    {categories[selectedCategorie].includes("precip") && (
                      <TableRow className="tr-data">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 9999,
                            backgroundColor: "#fff",
                          }}
                        >
                          precip(in)
                        </TableCell>
                        {weatherData1 &&
                          weatherData1.days &&
                          weatherData1.days.length > 0 &&
                          weatherData1.days.map((day, dayIndex) => (
                            <React.Fragment key={dayIndex}>
                              {day.hours &&
                                day.hours.map((hour, hourIndex) => (
                                  <TableCell key={hourIndex} align="right">
                                    {hour.precip > 0 ? (
                                      <FontAwesomeIcon
                                        icon={faCloudShowersHeavy}
                                      />
                                    ) : hour.cloudcover > 50 ? (
                                      <FontAwesomeIcon
                                        icon={faCloud}
                                        style={{ color: "grey" }}
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        icon={faSun}
                                        style={{ color: "yellow" }}
                                      />
                                    )}
                                  </TableCell>
                                ))}
                            </React.Fragment>
                          ))}
                      </TableRow>
                    )}
                    {categories[selectedCategorie].includes("temp") && (
                      <TableRow className="tr-data">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 9999,
                            backgroundColor: "#fff",
                          }}
                        >
                          {" "}
                          <FontAwesomeIcon icon={faTemperatureHigh} />
                          T(°F)
                        </TableCell>
                        {weatherData1 &&
                          weatherData1.days &&
                          weatherData1.days.length > 0 &&
                          weatherData1.days.map((day, dayIndex) => (
                            <React.Fragment key={dayIndex}>
                              {day.hours &&
                                day.hours.map((hour, hourIndex) => (
                                  <TableCell
                                    key={hourIndex}
                                    align="right"
                                    style={getTemperatureBackgroundColor(
                                      dayIndex,
                                      hourIndex
                                    )}
                                  >
                                    {hour.temp}
                                  </TableCell>
                                ))}
                            </React.Fragment>
                          ))}
                      </TableRow>
                    )}

                    {categories[selectedCategorie].includes("windspeed50") && (
                      <TableRow className="tr-data">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 9999,
                            backgroundColor: "#fff",
                          }}
                        >
                          {" "}
                          windspeed50(mph)
                        </TableCell>
                        {weatherData1 &&
                          weatherData1.days &&
                          weatherData1.days.length > 0 &&
                          weatherData1.days.map((day, dayIndex) => (
                            <React.Fragment key={dayIndex}>
                              {day.hours &&
                                day.hours.map((hour, hourIndex) => (
                                  <TableCell
                                    key={hourIndex}
                                    align="right"
                                    style={getWindspeed50BackgroundColor(
                                      hour.windspeed50,
                                      day.hours[hourIndex + 1]?.windspeed50
                                    )}
                                  >
                                    {hour.windspeed50}
                                  </TableCell>
                                ))}
                            </React.Fragment>
                          ))}
                      </TableRow>
                    )}

                    {categories[selectedCategorie].includes("winddir50") && (
                      <TableRow className="tr-data">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 9999,
                            backgroundColor: "#fff",
                          }}
                        >
                          {" "}
                          winddir50(°)
                        </TableCell>
                        {weatherData1 &&
                          weatherData1.days &&
                          weatherData1.days.length > 0 &&
                          weatherData1.days.map((day, dayIndex) => (
                            <React.Fragment key={dayIndex}>
                              {day.hours &&
                                day.hours.map((hour, hourIndex) => (
                                  <TableCell key={hourIndex} align="right">
                                    {getWindDirectionIcon1(hour.winddir50)}
                                  </TableCell>
                                ))}
                            </React.Fragment>
                          ))}
                      </TableRow>
                    )}

                    {categories[selectedCategorie].includes("windspeed80") && (
                      <TableRow className="tr-data">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 9999,
                            backgroundColor: "#fff",
                          }}
                        >
                          {" "}
                          windspeed80(mph)
                        </TableCell>
                        {weatherData1 &&
                          weatherData1.days &&
                          weatherData1.days.length > 0 &&
                          weatherData1.days.map((day, dayIndex) => (
                            <React.Fragment key={dayIndex}>
                              {day.hours &&
                                day.hours.map((hour, hourIndex) => (
                                  <TableCell
                                    key={hourIndex}
                                    align="right"
                                    style={getWindspeed50BackgroundColor(
                                      hour.windspeed80,
                                      day.hours[hourIndex + 1]?.windspeed80
                                    )}
                                  >
                                    {hour.windspeed80}
                                  </TableCell>
                                ))}
                            </React.Fragment>
                          ))}
                      </TableRow>
                    )}

                    {categories[selectedCategorie].includes("winddir80") && (
                      <TableRow className="tr-data">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 9999,
                            backgroundColor: "#fff",
                          }}
                        >
                          {" "}
                          winddir80(°)
                        </TableCell>
                        {weatherData1 &&
                          weatherData1.days &&
                          weatherData1.days.length > 0 &&
                          weatherData1.days.map((day, dayIndex) => (
                            <React.Fragment key={dayIndex}>
                              {day.hours &&
                                day.hours.map((hour, hourIndex) => (
                                  <TableCell key={hourIndex} align="right">
                                    {getWindDirectionIcon1(hour.winddir80)}
                                  </TableCell>
                                ))}
                            </React.Fragment>
                          ))}
                      </TableRow>
                    )}

                    {categories[selectedCategorie].includes("windspeed100") && (
                      <TableRow className="tr-data">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 9999,
                            backgroundColor: "#fff",
                          }}
                        >
                          {" "}
                          windspeed100(mph)
                        </TableCell>
                        {weatherData1 &&
                          weatherData1.days &&
                          weatherData1.days.length > 0 &&
                          weatherData1.days.map((day, dayIndex) => (
                            <React.Fragment key={dayIndex}>
                              {day.hours &&
                                day.hours.map((hour, hourIndex) => (
                                  <TableCell
                                    key={hourIndex}
                                    align="right"
                                    style={getWindspeed50BackgroundColor(
                                      hour.windspeed100,
                                      day.hours[hourIndex + 1]?.windspeed100
                                    )}
                                  >
                                    {hour.windspeed100}
                                  </TableCell>
                                ))}
                            </React.Fragment>
                          ))}
                      </TableRow>
                    )}

                    {categories[selectedCategorie].includes("winddir100") && (
                      <TableRow className="tr-data">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 9999,
                            backgroundColor: "#fff",
                          }}
                        >
                          {" "}
                          winddir100(°)
                        </TableCell>
                        {weatherData1 &&
                          weatherData1.days &&
                          weatherData1.days.length > 0 &&
                          weatherData1.days.map((day, dayIndex) => (
                            <React.Fragment key={dayIndex}>
                              {day.hours &&
                                day.hours.map((hour, hourIndex) => (
                                  <TableCell key={hourIndex} align="right">
                                    {getWindDirectionIcon1(hour.winddir100)}
                                  </TableCell>
                                ))}
                            </React.Fragment>
                          ))}
                      </TableRow>
                    )}

                    {categories[selectedCategorie].includes("feelslike") && (
                      <TableRow className="tr-data">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 9999,
                            backgroundColor: "#fff",
                          }}
                        >
                          {" "}
                          feelslike(°F)
                        </TableCell>
                        {weatherData1 &&
                          weatherData1.days &&
                          weatherData1.days.length > 0 &&
                          weatherData1.days.map((day, dayIndex) => (
                            <React.Fragment key={dayIndex}>
                              {day.hours &&
                                day.hours.map((hour, hourIndex) => (
                                  <TableCell
                                    key={hourIndex}
                                    align="right"
                                    style={getCellBackgroundGradient(
                                      hour.feelslike,
                                      day.hours[hourIndex + 1]?.feelslike
                                    )}
                                  >
                                    {hour.feelslike}
                                  </TableCell>
                                ))}
                            </React.Fragment>
                          ))}
                      </TableRow>
                    )}

                    {categories[selectedCategorie].includes("humidity") && (
                      <TableRow className="tr-data">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 9999,
                            backgroundColor: "#fff",
                          }}
                        >
                          {" "}
                          humidity(%)
                        </TableCell>
                        {weatherData1 &&
                          weatherData1.days &&
                          weatherData1.days.length > 0 &&
                          weatherData1.days.map((day, dayIndex) => (
                            <React.Fragment key={dayIndex}>
                              {day.hours &&
                                day.hours.map((hour, hourIndex) => (
                                  <TableCell key={hourIndex} align="right">
                                    {getHumidityIcon(hour.humidity)}
                                  </TableCell>
                                ))}
                            </React.Fragment>
                          ))}
                      </TableRow>
                    )}

                    {categories[selectedCategorie].includes("dew") && (
                      <TableRow className="tr-data">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 9999,
                            backgroundColor: "#fff",
                          }}
                        >
                          Dew(°F)
                        </TableCell>
                        {weatherData1 &&
                          weatherData1.days &&
                          weatherData1.days.length > 0 &&
                          weatherData1.days.map((day, dayIndex) => (
                            <React.Fragment key={dayIndex}>
                              {day.hours &&
                                day.hours.map((hour, hourIndex) => (
                                  <TableCell key={hourIndex} align="right">
                                    {hour.dew}
                                  </TableCell>
                                ))}
                            </React.Fragment>
                          ))}
                      </TableRow>
                    )}

                    {categories[selectedCategorie].includes("snow") && (
                      <TableRow className="tr-data">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 9999,
                            backgroundColor: "#fff",
                          }}
                        >
                          {" "}
                          snow(in)
                        </TableCell>
                        {weatherData1 &&
                          weatherData1.days &&
                          weatherData1.days.length > 0 &&
                          weatherData1.days.map((day, dayIndex) => (
                            <React.Fragment key={dayIndex}>
                              {day.hours &&
                                day.hours.map((hour, hourIndex) => (
                                  <TableCell key={hourIndex} align="right">
                                    {hour.snow > 0 ? (
                                      <FontAwesomeIcon icon={faSnowflake} />
                                    ) : (
                                      <FontAwesomeIcon icon={faCloud} />
                                    )}
                                  </TableCell>
                                ))}
                            </React.Fragment>
                          ))}
                      </TableRow>
                    )}

                    {categories[selectedCategorie].includes("snowdepth") && (
                      <TableRow className="tr-data">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 9999,
                            backgroundColor: "#fff",
                          }}
                        >
                          {" "}
                          snowdepth(in)
                        </TableCell>
                        {weatherData1 &&
                          weatherData1.days &&
                          weatherData1.days.length > 0 &&
                          weatherData1.days.map((day, dayIndex) => (
                            <React.Fragment key={dayIndex}>
                              {day.hours &&
                                day.hours.map((hour, hourIndex) => (
                                  <TableCell key={hourIndex} align="right">
                                    {hour.snowdepth}
                                  </TableCell>
                                ))}
                            </React.Fragment>
                          ))}
                      </TableRow>
                    )}

                    {categories[selectedCategorie].includes("windgust") && (
                      <TableRow className="tr-data">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 9999,
                            backgroundColor: "#fff",
                          }}
                        >
                          {" "}
                          windgust(mph)
                        </TableCell>
                        {weatherData1 &&
                          weatherData1.days &&
                          weatherData1.days.length > 0 &&
                          weatherData1.days.map((day, dayIndex) => (
                            <React.Fragment key={dayIndex}>
                              {day.hours &&
                                day.hours.map((hour, hourIndex) => (
                                  <TableCell
                                    key={hourIndex}
                                    align="right"
                                    style={getWindgustBackgroundColor(
                                      hour.windgust,
                                      day.hours[hourIndex + 1]?.windgust
                                    )}
                                  >
                                    {hour.windgust}
                                  </TableCell>
                                ))}
                            </React.Fragment>
                          ))}
                      </TableRow>
                    )}

                    {categories[selectedCategorie].includes("windspeed") && (
                      <TableRow className="tr-data">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 9999,
                            backgroundColor: "#fff",
                          }}
                        >
                          {" "}
                          windspeed(mph)
                        </TableCell>
                        {weatherData1 &&
                          weatherData1.days &&
                          weatherData1.days.length > 0 &&
                          weatherData1.days.map((day, dayIndex) => (
                            <React.Fragment key={dayIndex}>
                              {day.hours &&
                                day.hours.map((hour, hourIndex) => (
                                  <TableCell
                                    key={hourIndex}
                                    align="right"
                                    style={getWindspeed50BackgroundColor(
                                      hour.windspeed,
                                      day.hours[hourIndex + 1]?.windspeed
                                    )}
                                  >
                                    {hour.windspeed}
                                  </TableCell>
                                ))}
                            </React.Fragment>
                          ))}
                      </TableRow>
                    )}

                    {categories[selectedCategorie].includes("winddir") && (
                      <TableRow className="tr-data">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 9999,
                            backgroundColor: "#fff",
                          }}
                        >
                          {" "}
                          winddir(°)
                        </TableCell>
                        {weatherData1 &&
                          weatherData1.days &&
                          weatherData1.days.length > 0 &&
                          weatherData1.days.map((day, dayIndex) => (
                            <React.Fragment key={dayIndex}>
                              {day.hours &&
                                day.hours.map((hour, hourIndex) => (
                                  <TableCell key={hourIndex} align="right">
                                    {getWindDirectionIcon1(hour.winddir)}
                                  </TableCell>
                                ))}
                            </React.Fragment>
                          ))}
                      </TableRow>
                    )}

                    {categories[selectedCategorie].includes("pressure") && (
                      <TableRow className="tr-data">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 9999,
                            backgroundColor: "#fff",
                          }}
                        >
                          {" "}
                          pressure(inHg)
                        </TableCell>
                        {weatherData1 &&
                          weatherData1.days &&
                          weatherData1.days.length > 0 &&
                          weatherData1.days.map((day, dayIndex) => (
                            <React.Fragment key={dayIndex}>
                              {day.hours &&
                                day.hours.map((hour, hourIndex) => (
                                  <TableCell key={hourIndex} align="right">
                                    {hour.pressure}
                                  </TableCell>
                                ))}
                            </React.Fragment>
                          ))}
                      </TableRow>
                    )}

                    {categories[selectedCategorie].includes("visibility") && (
                      <TableRow className="tr-data">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 9999,
                            backgroundColor: "#fff",
                          }}
                        >
                          {" "}
                          visibility(mi)
                        </TableCell>
                        {weatherData1 &&
                          weatherData1.days &&
                          weatherData1.days.length > 0 &&
                          weatherData1.days.map((day, dayIndex) => (
                            <React.Fragment key={dayIndex}>
                              {day.hours &&
                                day.hours.map((hour, hourIndex) => (
                                  <TableCell key={hourIndex} align="right">
                                    {hour.visibility}
                                  </TableCell>
                                ))}
                            </React.Fragment>
                          ))}
                      </TableRow>
                    )}

                    {categories[selectedCategorie].includes("cloudcover") && (
                      <TableRow className="tr-data">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 9999,
                            backgroundColor: "#fff",
                          }}
                        >
                          {" "}
                          cloudcover(%)
                        </TableCell>
                        {weatherData1 &&
                          weatherData1.days &&
                          weatherData1.days.length > 0 &&
                          weatherData1.days.map((day, dayIndex) => (
                            <React.Fragment key={dayIndex}>
                              {day.hours &&
                                day.hours.map((hour, hourIndex) => (
                                  <TableCell
                                    key={hourIndex}
                                    align="right"
                                    style={getCloudcoverBackgroundColor(
                                      hour.cloudcover,
                                      day.hours[hourIndex + 1]?.cloudcover
                                    )}
                                  >
                                    {hour.cloudcover}
                                  </TableCell>
                                ))}
                            </React.Fragment>
                          ))}
                      </TableRow>
                    )}

                    {categories[selectedCategorie].includes(
                      "solarradiation"
                    ) && (
                      <TableRow className="tr-data">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 9999,
                            backgroundColor: "#fff",
                          }}
                        >
                          {" "}
                          solarradiation(W/m²)
                        </TableCell>
                        {weatherData1 &&
                          weatherData1.days &&
                          weatherData1.days.length > 0 &&
                          weatherData1.days.map((day, dayIndex) => (
                            <React.Fragment key={dayIndex}>
                              {day.hours &&
                                day.hours.map((hour, hourIndex) => (
                                  <TableCell
                                    key={hourIndex}
                                    align="right"
                                    style={getSolarRadiationBackgroundColor(
                                      hour.solarradiation,
                                      day.hours[hourIndex + 1]?.solarradiation
                                    )}
                                  >
                                    {hour.solarradiation}
                                  </TableCell>
                                ))}
                            </React.Fragment>
                          ))}
                      </TableRow>
                    )}

                    {categories[selectedCategorie].includes("solarenergy") && (
                      <TableRow className="tr-data">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 9999,
                            backgroundColor: "#fff",
                          }}
                        >
                          {" "}
                          solarenergy(kWh/m²)
                        </TableCell>
                        {weatherData1 &&
                          weatherData1.days &&
                          weatherData1.days.length > 0 &&
                          weatherData1.days.map((day, dayIndex) => (
                            <React.Fragment key={dayIndex}>
                              {day.hours &&
                                day.hours.map((hour, hourIndex) => (
                                  <TableCell
                                    key={hourIndex}
                                    align="right"
                                    style={getSolarEnergyBackgroundColor(
                                      hour.solarenergy,
                                      day.hours[hourIndex + 1]?.solarenergy
                                    )}
                                  >
                                    {hour.solarenergy}
                                  </TableCell>
                                ))}
                            </React.Fragment>
                          ))}
                      </TableRow>
                    )}

                    {categories[selectedCategorie].includes("uvindex") && (
                      <TableRow className="tr-data">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 9999,
                            backgroundColor: "#fff",
                          }}
                        >
                          {" "}
                          uvindex
                        </TableCell>
                        {weatherData1 &&
                          weatherData1.days &&
                          weatherData1.days.length > 0 &&
                          weatherData1.days.map((day, dayIndex) => (
                            <React.Fragment key={dayIndex}>
                              {day.hours &&
                                day.hours.map((hour, hourIndex) => (
                                  <TableCell key={hourIndex} align="right">
                                    {hour.uvindex}
                                  </TableCell>
                                ))}
                            </React.Fragment>
                          ))}
                      </TableRow>
                    )}

                    {categories[selectedCategorie].includes("severerisk") && (
                      <TableRow className="tr-data">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 9999,
                            backgroundColor: "#fff",
                          }}
                        >
                          {" "}
                          severerisk(%)
                        </TableCell>
                        {weatherData1 &&
                          weatherData1.days &&
                          weatherData1.days.length > 0 &&
                          weatherData1.days.map((day, dayIndex) => (
                            <React.Fragment key={dayIndex}>
                              {day.hours &&
                                day.hours.map((hour, hourIndex) => (
                                  <TableCell key={hourIndex} align="right">
                                    {hour.severerisk}
                                  </TableCell>
                                ))}
                            </React.Fragment>
                          ))}
                      </TableRow>
                    )}

                    {categories[selectedCategorie].includes("ghiradiation") && (
                      <TableRow className="tr-data">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 9999,
                            backgroundColor: "#fff",
                          }}
                        >
                          {" "}
                          ghiradiation(W/m²)
                        </TableCell>
                        {weatherData1 &&
                          weatherData1.days &&
                          weatherData1.days.length > 0 &&
                          weatherData1.days.map((day, dayIndex) => (
                            <React.Fragment key={dayIndex}>
                              {day.hours &&
                                day.hours.map((hour, hourIndex) => (
                                  <TableCell
                                    key={hourIndex}
                                    align="right"
                                    style={getGhiRadiationBackgroundColor(
                                      hour.ghiradiation,
                                      day.hours[hourIndex + 1]?.ghiradiation
                                    )}
                                  >
                                    {hour.ghiradiation}
                                  </TableCell>
                                ))}
                            </React.Fragment>
                          ))}
                      </TableRow>
                    )}

                    {categories[selectedCategorie].includes("dniradiation") && (
                      <TableRow className="tr-data">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 9999,
                            backgroundColor: "#fff",
                          }}
                        >
                          {" "}
                          dniradiation(W/m²)
                        </TableCell>
                        {weatherData1 &&
                          weatherData1.days &&
                          weatherData1.days.length > 0 &&
                          weatherData1.days.map((day, dayIndex) => (
                            <React.Fragment key={dayIndex}>
                              {day.hours &&
                                day.hours.map((hour, hourIndex) => (
                                  <TableCell
                                    key={hourIndex}
                                    align="right"
                                    style={getDniRadiationBackgroundColor(
                                      hour.dniradiation,
                                      day.hours[hourIndex + 1]?.dniradiation
                                    )}
                                  >
                                    {hour.dniradiation}
                                  </TableCell>
                                ))}
                            </React.Fragment>
                          ))}
                      </TableRow>
                    )}

                    {categories[selectedCategorie].includes("difradiation") && (
                      <TableRow className="tr-data">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 9999,
                            backgroundColor: "#fff",
                          }}
                        >
                          {" "}
                          difradiation(W/m²)
                        </TableCell>
                        {weatherData1 &&
                          weatherData1.days &&
                          weatherData1.days.length > 0 &&
                          weatherData1.days.map((day, dayIndex) => (
                            <React.Fragment key={dayIndex}>
                              {day.hours &&
                                day.hours.map((hour, hourIndex) => (
                                  <TableCell
                                    key={hourIndex}
                                    align="right"
                                    style={getDifRadiationBackgroundColor(
                                      hour.difradiation,
                                      day.hours[hourIndex + 1]?.difradiation
                                    )}
                                  >
                                    {hour.difradiation}
                                  </TableCell>
                                ))}
                            </React.Fragment>
                          ))}
                      </TableRow>
                    )}

                    {categories[selectedCategorie].includes("gtiradiation") && (
                      <TableRow className="tr-data">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 9999,
                            backgroundColor: "#fff",
                          }}
                        >
                          {" "}
                          gtiradiation(W/m²)
                        </TableCell>
                        {weatherData1 &&
                          weatherData1.days &&
                          weatherData1.days.length > 0 &&
                          weatherData1.days.map((day, dayIndex) => (
                            <React.Fragment key={dayIndex}>
                              {day.hours &&
                                day.hours.map((hour, hourIndex) => (
                                  <TableCell
                                    key={hourIndex}
                                    align="right"
                                    style={getGtiRadiationBackgroundColor(
                                      hour.gtiRadiation,
                                      day.hours[hourIndex + 1]?.gtiRadiation
                                    )}
                                  >
                                    {hour.gtiradiation}
                                  </TableCell>
                                ))}
                            </React.Fragment>
                          ))}
                      </TableRow>
                    )}

                    {categories[selectedCategorie].includes("sunelevation") && (
                      <TableRow className="tr-data">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 9999,
                            backgroundColor: "#fff",
                          }}
                        >
                          {" "}
                          sunelevation(°)
                        </TableCell>
                        {weatherData1 &&
                          weatherData1.days &&
                          weatherData1.days.length > 0 &&
                          weatherData1.days.map((day, dayIndex) => (
                            <React.Fragment key={dayIndex}>
                              {day.hours &&
                                day.hours.map((hour, hourIndex) => (
                                  <TableCell
                                    key={hourIndex}
                                    align="right"
                                    style={getSunElevationBackgroundColor(
                                      hour.sunelevation,
                                      day.hours[hourIndex + 1]?.sunelevation
                                    )}
                                  >
                                    {hour.sunelevation}
                                  </TableCell>
                                ))}
                            </React.Fragment>
                          ))}
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        )}
      </Paper>
    </div>
  );
}

export default Data;
