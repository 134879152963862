import ResponsiveAppBar from "./navbar";

function HistoricalWind() {
  return (
    <div>
      <ResponsiveAppBar />
      <div className="day-selector">
        <form className="form">
          <label htmlFor="day" className="label">
            Select a Day:
          </label>
          <input type="date" id="day" className="input" />
          <button type="submit" className="button">
            Generate
          </button>
        </form>
      </div>
    </div>
  );
}
export default HistoricalWind;
