// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.weather-table {
    width: 100%;
    border-collapse: collapse;
    margin: 3px 0;
  }
  
  .weather-table th, .weather-table td {
    text-align: center;
  }
  
  .weather-table th {
    background-color: #f2f2f2;

    font-size: 13px;
  }
  
  .weather-table td {
    background-color: #f9f9f9;
    font-size: 9px;
  }
  
  .weather-table th[rowspan="2"] {
    vertical-align: middle;
  }
  `, "",{"version":3,"sources":["webpack://./src/WeatherForecast.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;IACzB,aAAa;EACf;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;;IAEzB,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,cAAc;EAChB;;EAEA;IACE,sBAAsB;EACxB","sourcesContent":[".weather-table {\n    width: 100%;\n    border-collapse: collapse;\n    margin: 3px 0;\n  }\n  \n  .weather-table th, .weather-table td {\n    text-align: center;\n  }\n  \n  .weather-table th {\n    background-color: #f2f2f2;\n\n    font-size: 13px;\n  }\n  \n  .weather-table td {\n    background-color: #f9f9f9;\n    font-size: 9px;\n  }\n  \n  .weather-table th[rowspan=\"2\"] {\n    vertical-align: middle;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
