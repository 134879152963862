import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthGuard = ({ children }) => {
    const navigate = useNavigate();
    const isLoggedIn = localStorage.getItem('userToken');

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/', { replace: true });
        }
    }, [isLoggedIn, navigate]);

    return isLoggedIn ? children : null; // Render nothing until the effect runs
};

export default AuthGuard;