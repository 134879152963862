import React, { useState } from "react";
import axios from "axios";
import "./DaySelector.css"; // Ensure you have this CSS file
import ResponsiveAppBar from "./navbar";

function WindMapPlot() {
  const [selectedDay, setSelectedDay] = useState("");
  const [loading, setLoading] = useState(false);
  const [mediaSrc, setMediaSrc] = useState({ gif: "", mp4: "" });

  const handleDayChange = (event) => {
    setSelectedDay(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedDay) return;

    setLoading(true);
    setMediaSrc({ gif: "", mp4: "" });

    try {
      const response = await axios.get(
        `https://weather.playalab.com:8443/plot_wind/${selectedDay}`
      );
      if (response.status === 200) {
        const basePath = `https://weather.playalab.com:8443/media/${selectedDay}`;
        setMediaSrc({
          gif: response.data.gif_url,
          mp4: `${basePath}/wind_animation_${selectedDay}.mp4`,
        });
      }
    } catch (error) {
      console.error("Error fetching the data:", error);
      alert("Failed to fetch data.");
    }

    setLoading(false);
  };
const downloadGif = () =>{
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = mediaSrc.gif;
      a.download = `${selectedDay}.gif`;
      document.body.appendChild(a);
      a.click();
      a.remove()

  }
  return (
    <>
      <ResponsiveAppBar />
      <div className="day-selector">
        <form onSubmit={handleSubmit} className="form">
          <label htmlFor="day" className="label">
            Select a Day:
          </label>
          <input
            type="date"
            id="day"
	  min="2023-01-01"
            value={selectedDay}
            onChange={handleDayChange}
            className="input"
          />
          <button type="submit" className="button">
            Generate
          </button>
          
        </form>
        {mediaSrc.gif && (
          <button 
          className="button"
          onClick={downloadGif}
          >
            Download
          </button>
        )}

        
        {loading && <div className="loader">Loading...</div>}
        {mediaSrc.gif && (
          <div className="media ">
            {mediaSrc.gif && (
              <img
                src={mediaSrc.gif}
                alt="Wind Animation GIF"
                className="image"
              />
            )}

            {/*<video src={mediaSrc.mp4} controls className="video"></video>*/}
          </div>
        )}
      </div>
    </>
  );
}

export default WindMapPlot;
