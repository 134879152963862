import React from "react";
import "./App.css";
// Importez votre composant WindyMap depuis le bon chemin
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AuthGuard from "./AuthGuard";
import Home from "./home";
import Historical from "./Historical";
import Login from "./Login";
import WeatherForecast from "./WeatherForcast";
import WindMapPlot from "./WindMapPlot";
import HistoricalWind from "./HistoricalWind";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />

        <Route
          path="/home"
          element={
            <AuthGuard>
              <Home />
            </AuthGuard>
          }
        />
        <Route
          path="/history"
          element={
            <AuthGuard>
              <Historical />
            </AuthGuard>
          }
        />

        <Route
          path="/weather-models"
          element={
            <AuthGuard>
              <WeatherForecast />
            </AuthGuard>
          }
        />

        <Route
          path="/wind-map"
          element={
            <AuthGuard>
              <WindMapPlot />
            </AuthGuard>
          }
        />

        <Route
          path="/wind-historical"
          element={
            <AuthGuard>
              <HistoricalWind />
            </AuthGuard>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
